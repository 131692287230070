$password_strengthen: true;
$opc_update: true;
$text-direction: rtl;
$rdirection: left;
$ldirection: right;
$tstar_font_update: true;

// 2021 styling refresh for SPP.
$cr21_spp: false;

// 2020 styling refresh.
$cr20: true;

$gnav_update: true;

// 2021 styling refresh.
$cr21: true;
